<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <Menu
          name="check"
          style="background: black;z-index: 999;position: relative;"
          @change="onProInfoChange($event)"
        />

        <dv-loading v-if="loading">Loading...</dv-loading>
        <div
          v-else
          id="content"
        >
          <div id="center">
            <h3>检查数据分析</h3>
            <div>
              <Echart
                :options="tableChartOptions"
                id="checkChart"
                height="8rem"
                width="100%"
              ></Echart>
            </div>
          </div>
          <div id="right">
            <div id="right-up">
              <h3>安全隐患检查统计</h3>
              <patrol-table
                ref="patroltable1"
                :proId="pro.Id"
                :type="1"
              ></patrol-table>
            </div>
            <div id="right-down">
              <h3>质量隐患检查统计</h3>
              <patrol-table
                ref="patroltable2"
                :proId="pro.Id"
                :type="2"
              ></patrol-table>
            </div>
          </div>

        </div>
      </div>

    </dv-full-screen-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
// import { message } from "ant-design-vue";
import ProInfoCache from "@/utils/cache/ProInfoCache"

//这是后端上传的格式
const checkChartX = [
  {Key:'2021-11-11 08',SafetyCount:10,QualityCount:15,CountAll:25},
  {Key:'2021-11-11 09',SafetyCount:10,QualityCount:12,CountAll:22},
  {Key:'2021-11-11 10',SafetyCount:11,QualityCount:15,CountAll:26},
  {Key:'2021-11-11 11',SafetyCount:15,QualityCount:14,CountAll:29},
  {Key:'2021-11-11 12',SafetyCount:12,QualityCount:13,CountAll:25},
  {Key:'2021-11-11 13',SafetyCount:11,QualityCount:12,CountAll:23},
  {Key:'2021-11-11 14',SafetyCount:13,QualityCount:11,CountAll:24},
  {Key:'2021-11-11 15',SafetyCount:14,QualityCount:10,CountAll:24},
  {Key:'2021-11-11 16',SafetyCount:14,QualityCount:9,CountAll:23},
  {Key:'2021-11-11 17',SafetyCount:15,QualityCount:8,CountAll:23},
  {Key:'2021-11-11 18',SafetyCount:16,QualityCount:7,CountAll:23},
  {Key:'2021-11-11 19',SafetyCount:17,QualityCount:8,CountAll:25},
]

moment.locale("zh-cn");
import moment from "moment";
import Echart from "@/common/echart";
import PatrolTable from "./patrol-table.vue"

export default {
  data() {
    return {
      loading: true,
      pro:{},
      url:'',
      //图表数据
      checkChart:{
        Key:[],
        SafetyCount:[],
        QualityCount:[]
      },
      checkChartX,
      tableChartOptions:{}
    };
  },
  components: {
    Menu,
    Echart,
    PatrolTable
  },
  created: function () {
    this.loading=false
  },
  mounted() {
    this.pro = ProInfoCache.getProInfo()
    this.load();
  },
  watch: {
    checkChart:{
      handler(newData) {
        // console.info(newData)
        this.tableChartOptions = {
          title: {
            text: "",
            subtext: "",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["安全检查隐患", "质量检查隐患"],
          },
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: "none",
              },
              dataView: { readOnly: false },
              magicType: { type: ["line", "bar"] },
              restore: {},
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: newData.Key,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          series: [
            {
              name: "安全检查隐患",
              type: "line",
              data: newData.SafetyCount,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
              // markLine: {
              //   data: [{ type: "average", name: "平均值" }],
              // },
            },
            {
              name: "质量检查隐患",
              type: "line",
              data: newData.QualityCount,
              markPoint: {
                // data: [{ name: "周最低", value: 2, xAxis: 1, yAxis: 1.5 }],
                 data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
              // markLine: {
              //   data: [
              //     { type: "average", name: "平均值" },
              //     [
              //       {
              //         symbol: "none",
              //         x: "90%",
              //         yAxis: "max",
              //       },
              //       {
              //         symbol: "circle",
              //         label: {
              //           normal: {
              //             position: "start",
              //             formatter: "最大值",
              //           },
              //         },
              //         type: "max",
              //         name: "最高点",
              //       },
              //     ],
              //   ],
              // },
            },
          ],
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    onProInfoChange(e){
      this.pro = e;
      //放在mounted中引用会因为组件还未创建而导致无法使用
      this.$refs.patroltable1.load()
      this.$refs.patroltable2.load()
    },
    load(){
      this.getCountList()
    },
    getCountList(){
      // 测试数据
      // this.checkChartX.forEach(x=>{
      //   this.checkChart.Key.push(x.Key)
      //   this.checkChart.SafetyCount.push(x.SafetyCount)
      //   this.checkChart.QualityCount.push(x.QualityCount)
      // })
      this.loading = true
      this.$http
        .post('/Patrol/PatrolRecord/GetRecordCount', {
          ProId:this.pro.Id,
          RiskFlag:1
        })
        .then(resJson => {
          this.loading = false
          if(resJson.Success){
            resJson.Data.forEach(x=>{
              this.checkChart.Key.push(x.Key)
              this.checkChart.SafetyCount.push(x.SafetyCount)
              this.checkChart.QualityCount.push(x.QualityCount)
              })
          }
        })
    }
  },
};
</script>
<style lang="scss">
.container-title {
  display: none !important;
}

#content {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.3rem 0 0.3rem;
  height: 10.3rem;
  overflow: hidden;
  h3 {
    font-weight: 900;
    color: white;
    // padding: 15px;
    padding-top: 15px;
    padding-left: 15px;
  }

  #center {
    width: 1000px;
    height: 784px;
    margin: 0 auto;
    // background: #03263d;
    background: url("../../assets/image/g_youshang.png") no-repeat center center;
    background-size: 100% 100%;
    position: relative;
  }
  #right {
    height: 100%;
    margin-left: 1.4%;
    #right-up {
      display: block;
      width: 10rem;
      height: 4.75rem;
      background: url("../../assets/image/g_youshang.png") no-repeat center
        center;
      background-size: 100% 100%;
    }
    #right-down {
      margin-top: 0.3rem;
      display: block;
      width: 10rem;
      height: 4.75rem;
      background: url("../../assets/image/g_youshang.png") no-repeat center
        center;
      background-size: 100% 100%;
    }
  }
}
</style>